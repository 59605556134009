<template>
  <div class="projectList">
    <el-card class="mb-16">
      <el-tabs v-model="searchForm.fileStatus" @tab-click="handleSearch">
        <el-tab-pane label="待审核" name="1"></el-tab-pane>
        <el-tab-pane label="审核不通过" name="4"></el-tab-pane>
        <el-tab-pane label="已下架" name="3"></el-tab-pane>
        <el-tab-pane label="已审核" name="2"></el-tab-pane>
      </el-tabs>
      <div class="search-container">
        <el-form
          size="small"
          label-position="right"
          class="migration-search"
          style="border: none"
          inline
        >
          <!-- 公司名称/简称  -->
          <el-form-item class="inline" label="文件名称：">
            <el-input
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.fileName"
              placeholder="请输入文件名称"
              clearable
              @keyup.native.enter="handleSearch"
              maxlength="17"
            />
          </el-form-item>
          <el-form-item class="inline" label="所属项目：">
            <el-input
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.projectName"
              placeholder="请输入项目名称"
              clearable
              @keyup.native.enter="handleSearch"
              maxlength="17"
            />
          </el-form-item>
          <el-form-item class="inline" label="文件类型：">
            <el-select v-model="searchForm.attachmentType" placeholder="请选择文件类型">
              <el-option
                v-for="item in attachmentTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline" label="创建人：">
            <el-input
              style="width: 220px; margin-right: 15px"
              v-model="searchForm.createByName"
              placeholder="请输入创建人"
              clearable
              @keyup.native.enter="handleSearch"
              maxlength="17"
            />
          </el-form-item>
          <el-button
            type="primary"
            size="medium"
            @click="handleSearch"
            :disabled="isSearch"
            >查询</el-button
          >
          <el-button size="medium" @click="handleReset" plain>重置</el-button>
        </el-form>
      </div>
    </el-card>
      
    <el-card class="mb-16">
      <div class="tabHeader">
        <p>共{{total}}条数据</p>
      </div>
      <myTable
        class="my-table"
        ref="myTable"
        :tableData="tableData"
        :tableColumns="tableColumns"
        tableBorder
        :page="pageInfo.pageNum"
        :rows="pageInfo.pageSize"
        :total="total"
        isPagination
        @handleChange="handleChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column slot="fileName" label="文件名称" width="280px">
          <template slot-scope="{ row }">
            <div class="title-box" v-if="row.attachmentType !== 6">
              <img :src="getIcon(row.fileType)" style="width:40px;height:40px;">
              <div class="title-right">
                <p class="title">{{ row.fileName }}</p>
                <i v-if="user.userType == 1" class="el-icon-edit"  @click="editName(row)"></i>
                <!-- <p class="content">{{ row.desc }}</p> -->
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column slot="attachmentType" label="文件类型" width="180px">
          <template slot-scope="{ row }">
            <p class="title">{{ row.attachmentType| attachmentTypeStr}}</p>
          </template>
        </el-table-column>
        <el-table-column slot="fileSize" label="文件大小" width="180px">
          <template slot-scope="{ row }">
            <p class="title">{{ row.fileSize| getFileSize}}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column slot="fileStatus" label="审核状态" width="180px">
          <template slot-scope="{ row }">
            <span>{{row.fileStatus|getProjectStatusName}}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          slot="operate"
          label="操作"
          fixed="right"
          width="220px"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button v-if="row.fileStatus == 1" @click="() => handleOperation(row, operate.PASS)" type="text">通过</el-button>
            <el-button  v-if="row.fileStatus == 1" @click="() => handleOperation(row, operate.UNPASS)" type="text">不通过</el-button>
            <!-- <el-button  v-if="row.fileStatus == 2" @click="() => handleOperation(row, operate.DELETE)" type="text" style="color:#ff0000;">下架</el-button> -->
            <el-button @click="() => handleOperation(row, operate.PREVIEW)" type="text">在线预览</el-button>
          </template>
        </el-table-column>
      </myTable>
    </el-card>
    <detail v-if="false" ref="detail" />
    <previewDialog ref="previewDialog"/>
  </div>
</template>
<script>

import myTable from "../../components/myTable.vue";
import Detail from '../project/detail.vue'
import previewDialog from '../project/previewDialog.vue'
import { mapState } from "vuex";
export default {
  filters: {
    getProjectStatusName(value) {
      switch(value){
        case 1:
          return '待审核'
        case 2:
          return '正常'
        case 3:
          return '下架'
        case 4:
          return '审核不通过'
      }
    },
    attachmentTypeStr(value) {
      switch(value){
        case 1:
          return '项目BP'
        case 2:
          return '访谈纪要'
        case 3:
          return '立项报告'
        case 4:
          return '尽调报告'
        case 5:
          return '投决报告'
        case 6:
          return '联系人'
      }
    },
    getFileSize(value) {
      return Math.floor(value/1024/1024 * 100)/100 +'MB';
    }
  },
  components: {
    myTable,
    Detail,
    previewDialog
  },
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      // 审核不通过意见
      notPassValue: '',
      //文件类型，1项目BP,2访谈纪要,3立项报告,4尽调报告,5投决报告
      attachmentTypes:[
        {label:'项目BP',value:1},
        {label:'访谈纪要',value:2},
        {label:'立项报告',value:3},
        {label:'尽调报告',value:4},
        {label:'投决报告',value:5},
        {label:'联系人',value:6}
      ],
      isSearch: false,
      searchForm: {
        projectName: "",
        createByName: "",
        fileName: "",
        attachmentType: "",
        fileStatus:'1'
      },
      industryTypeList: [],
      addressList: [],
      businessIncomeList: [],
      netProfitList: [],
      operate: {
        PASS: 1,
        UNPASS: 2,
        DELETE: 3,
        PREVIEW: 4,
      },
      total: 0,
      tableData: [],
      tableColumns: [
        {
          label: "文件名称",
          slot: "fileName",
          showTooltip: true,
          align: "center",
        },
        {
          label: "文件类型",
          slot: "attachmentType",
          showTooltip: true,
          align: "center",
        },
        // {
        //   label: "文件大小",
        //   slot: "fileSize",
        //   showTooltip: true,
        //   align: "center",
        // },
        {
          label: "审核状态",
          slot: "fileStatus",
          showTooltip: true,
          align: "center",
        },
        {
          label: "所属项目",
          prop: "projectName",
          showTooltip: true,
          align: "center",
        },
        {
          label: "营收",
          prop: "businessIncomeName",
          showTooltip: true,
          align: "center",
          minWidth: 150
        },
        {
          label: "净利润",
          prop: "netProfitName",
          showTooltip: true,
          align: "center",
          minWidth: 150
        },
        {
          label: "创建人",
          prop: "createByName",
          showTooltip: true,
          align: "center",
        },
        {
          label: "创建时间",
          prop: "createTime",
          showTooltip: true,
          align: "center",
          minWidth: 180
        },
        {
          label: "操作",
          slot: "operate",
          width: "80px",
          showTooltip: true,
          align: "center",
        },
      ],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      pdfIcon: require('@/assets/fileIcon/pdf.png'),
      docIcon: require('@/assets/fileIcon/word.png'),
      pptIcon: require('@/assets/fileIcon/ppt.png'),
    };
  },
  mounted() {
    // this.getDropDownData();
    this.handleSearch();
  },
  methods: {
    // 修改附件名称
    editName(item) {
      const value = (item.fileName || '').match(/^(.*?)(\.[^.]*)$/)
      this.$prompt('请输入附件名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: value[1],
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              const { inputValue } = instance
              if (!inputValue) {
                return this.$message.warning('请输入附件名称')
              }
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              this.$http.Post(this.$api.updateAttachment, {
                id:  item.id,
                fileName: `${inputValue}${value[2]}`
              }).then(() => {
                done();
                instance.confirmButtonLoading = false;
                this.getPageList()
              })
            } else {
              done()
            }
          }
        })
    },
    // 1PDF、2word、3PPT
    getIcon(fileType){
      switch(fileType){
        case 1:
          return this.pdfIcon
        case 2:
          return this.docIcon
        case 3:
          return this.pptIcon
      }
    },
    // 获取下拉数据
    async getDropDownData() {
      // 获取行业 id 1
      let data1 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 1,
      });
      this.industryTypeList = data1.data;

      // 获取营收 id 2
      let data2 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 2,
      });
      this.businessIncomeList = data2.data;

      // 获取净利规模 id 3
      let data3 = await this.$http.Get(this.$api.insideListByGroupId, {
        labelGroupId: 3,
      });
      this.netProfitList = data3.data;

      // 获取地区
      let data4 = await this.$http.Get(this.$api.getAddressList, { level: 2 });
      this.addressList = data4.data.children;
      console.log("addresslist", this.addressList);
    },
    handleReset() {
      this.pageInfo.pageNum = 1;
      this.searchForm = {
        projectName: "",
        createByName: "",
        fileName: "",
        attachmentType: "",
        fileStatus:'1'
      };
      this.handleSearch();
    },
    handleSearch() {
      this.pageInfo.pageNum = 1;
      this.getPageList();
    },
    // 获取数据
    getPageList() {
      const params = {
        ...this.searchForm,
        ...this.pageInfo,
        orderType: 2
      };
      this.$http.Post(this.$api.listApprovingAttachmentByPage, params).then((res) => {
        // console.log(res, "res---------")
        this.total = Number(res.data.total);
        this.tableData = res.data.records;
      });
    },
    handleChange(val) {
      this.pageInfo = { ...val };
      this.getPageList();
    },
    // 表格选择
    handleSelectionChange(val) {
      console.log("表格选择", val);
    },
    // 操作函数
    handleOperation(row, type) {
      if (type === this.operate.PASS) {
        this.operateBtn(row.id,2,'确定通过审批吗?')
      } else if (type === this.operate.UNPASS) {
        // this.operateBtn(row.id,4,'确定不通过审批吗?')
        this.notPass(row.id)
      } else if (type === this.operate.DELETE) {
        this.operateBtn(row.id,3,'确定下架吗?')
      } else if (type === this.operate.PREVIEW) {
        if (row.attachmentType != 6) return this.$refs.previewDialog.open(row.id)
        const h = this.$createElement;
        const content = h('div', null, [
            h('div', { style: 'margin: 10px auto;' }, `联系人姓名: ${row.enterpriseConcatName}`),
            h('div', { style: 'margin: 10px auto;' }, `联系人职务: ${row.enterpriseConcatPosition || '--'}`),
            h('div', { style: 'margin: 10px auto;' }, `联系人手机号: ${row.enterpriseConcatPhone}`),
            h('div', null, ['联系人名片: ', row.enterpriseConcatBusinessCard ? h('img', {attrs: { src: row.enterpriseConcatBusinessCard },  style: 'width: 100%;margin-top: 10px;' }) : '--']),
          ])
        this.$msgbox({
          title: '联系人',
          message: content,
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: '确定'
        })
      }
    },
    // 审核不通过
    onCommentInputChange() {
      this.notPassValue = document.getElementById("notPassInput").value;
    },
    notPass(id) {
      console.log(id)
      const _this = this
      const h = this.$createElement;
      this.$msgbox({
          title: '审批不通过',
          message: h('div', {
              attrs: {
                  class: 'el-textarea',
              },
          }, [
              h('textarea', {
                  attrs: {
                      class: 'el-textarea__inner',
                      autocomplete: 'off',
                      rows: 4,
                      id:'notPassInput',
                      placeholder: '请输入审批意见'
                  },
                  value: _this.notPassValue,
                  on: { input: _this.onCommentInputChange }
              })
          ]),
          showCancelButton: true,
          confirmButtonText: '提交',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                  if (!this.notPassValue) {
                    return this.$message.warning('请输入审批意见')
                  }
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = '正在提交...';
                  this.$http.Post(this.$api.updateAttachmentStatus, {
                    id,
                    fileStatus: 4,
                    approveDesc: this.notPassValue
                  }).then(() => {
                    this.handleSearch()
                    instance.confirmButtonLoading = false;
                    instance.confirmButtonText = '提交';
                    document.getElementById("notPassInput").value = ''
                    this.notPassValue = ''
                    this.$message({
                      type: 'success',
                      message: '操作成功'
                    }); 
                    done()
                  })
              } else {
                  done();
              }
          }
      })
    },
    operateBtn(id,type,msg) {
      this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$http.Post(this.$api.updateAttachmentStatus, {
            id:id,
            fileStatus: type,
          });
          this.$message({
            type: 'success',
            message: '操作成功'
          }); 
          this.handleSearch()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    
    // 处理附类型
    handleAttachmentType(type, attachment) {
      if (type == 1) {
        this.attachmentList.projectBP.push(attachment);
      } else if (type == 2) {
        this.attachmentList.interview.push(attachment);
      } else if (type == 3) {
        this.attachmentList.approval.push(attachment);
      } else if (type == 4) {
        this.attachmentList.investigation.push(attachment);
      } else if (type == 5) {
        this.attachmentList.vote.push(attachment);
      }
    },
    // 获取附件数
    async getAttachmentNum(type) {
      let res = await this.$http.Get(this.$api.getAttachmentCount, {
        projectId: this.projectId,
        attachmentType: type,
      });
      if (res.code == 200) {
        this.attachmentNum = res.data.bpCount;
      }
    },
    // 打开弹窗
    openDialog(type) {
      if (type == 1) {
        this.dialogVisibleBP = true;
      } else if (type == 2) {
        this.dialogVisibleInterview = true;
      } else if (type == 3) {
        this.dialogVisibleVote = true;
      } else if (type == 4) {
        this.dialogVisibleApproval = true;
      } else if (type == 5) {
        this.dialogVisibleInvestigation = true;
      }
      this.drawer = false;
    },
    // 切换tab方法
    handleClick(tab) {
      // console.log(tab, event);
      this.activeName = tab.name;
      if (this.activeName != "6") {
        this.getAttachmentNum(this.activeName);
      }
    },
    // 详情关闭的回调放发
    detailDrawerHandleClose() {
      this.attachmentList = {
        projectBP: [],
        interview: [],
        approval: [],
        investigation: [],
        vote: [],
      };
      this.activeName = "1";
      this.attachmentNum = 0;
      this.detailDrawer = false;
      console.log("详情关闭的回调放发");
    },
    // 收藏方法
    collectProjectFun() {
      this.$message.warning("收藏接口还未对接"); 
    },
    // 编辑方法
    editProjectFun() {
      this.$message.warning("编辑接口还未对接"); 
    },
    // 删除方法
    deleteProjectFun() {
      this.$message.warning("删除接口还未对接"); 
    },
  },
};
</script>
<style lang="less" scoped>
.projectList {
  .tabHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .title-box {
    display: flex;
    align-items: center;
    .img {
      width: 40px;
      height: 40px;
    }

    .title-right {
      margin-left: 10px;
      padding: 10px;

      .title {
        font-size: 18px;
        color: #333;
      }

      .content {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .box-container {
    display: flex;
    flex-wrap: wrap;

    .box {
      width: 200px;
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      margin: 24px;
      transition: all 0.3s ease;
      color: #777;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
        color: #000;
      }
    }
  }
  .el-icon-edit{
    cursor: pointer;
  }
  .detail-drawer {
    .detail-box {
      .detail-top {
        display: flex;
        .detail-top-left {
          padding: 10px 20px;
          width: 80%;
          height: 120px;
          box-sizing: border-box;
          // border:1px solid #ccc;
          display: flex;
          .img {
            width: 80px;
            background-color: #ccc;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            border-radius: 6px;
          }
          .content {
            margin-left: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > div {
              color: #888;
              font-size: 14px;
            }
            .content-title {
              color: black;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
        .detail-top-right {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .divide {
        height: 20px;
        border-bottom: 1px solid #ccc;
        // color:#666;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
<style lang="less"></style>
